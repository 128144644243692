@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');

* {
  list-style: none;
  text-decoration: none;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Open Sans', sans-serif;
}

body {
  background: #f5f6fa;
  /* overflow: hidden; */
}

.wrapper .sidebar {
  background: -webkit-linear-gradient(left, #b1656c, #b1656c);
  position: fixed;
  top: 122px;
  left: 0;
  width: 225px;
  height: 100%;
  padding: 20px 0;
  transition: all 0.5s ease;
}

.wrapper .sidebar .profile {
  margin-bottom: 30px;
  text-align: center;
}

.wrapper .sidebar .profile img {
  display: block;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin: 0 auto;
}

.wrapper .sidebar .profile h3 {
  color: #ffffff;
  margin: 10px 0 5px;
}

.wrapper .sidebar .profile p {
  color: white;
  font-size: 14px;
}

.wrapper .sidebar ul li a {
  display: block;
  padding: 13px 30px;
  color: rgb(241, 237, 237);
  font-size: 16px;
  text-decoration: none;
  position: relative;
}

.wrapper .sidebar ul li a .icon {
  color: #dee4ec;
  width: 30px;
  display: inline-block;
}


.wrapper .sidebar ul li a:hover,
.wrapper .sidebar ul li a.active {
  color: #434343;

  background: white;
  border-right: 2px solid #434343;
}

.wrapper .sidebar ul li a:hover .icon,
.wrapper .sidebar ul li a.active .icon {
  color: #434343;
}

.wrapper .sidebar ul li a:hover:before,
.wrapper .sidebar ul li a.active:before {
  display: block;
}

.wrapper .section {
  width: calc(100% - 225px);
  margin-left: 225px;
  transition: all 0.5s ease;
}

.wrapper .section .top_navbar {
  background: -webkit-linear-gradient(left, #b1656c, #b1656c);
  height: 50px;
  display: flex;
  align-items: center;
  padding: 0 30px;
  width: 100%;
  position: fixed;
  left: 0px;
}

.wrapper .section .top_navbar .hamburger a {
  font-size: 28px;
  color: #f4fbff;
}

.wrapper .section .top_navbar .hamburger a:hover {
  color: white;
}

.wrapper.active .sidebar {
  margin-left: -225px;
}

.wrapper.active .section {
  margin-left: 0;
  width: 100%;
}


.seprateSidebar {
  color: white;
  margin-top: -20px;
}

.Companylogo img {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  margin-right: 12px;
}

.Companylogo h1 {
  color: #434343;
  font-size: 26px;
  font-weight: bolder;
}

.Companylogo {
  display: flex;
  align-items: baseline;
  justify-content: flex-start;
  margin-left: 10px;
  margin-top: 10px;
  margin-bottom: 5px;
}

.gameName {
  flex: 1;
  text-align: center;
  color: white;
  font-size: 30px;
  font-weight: bold;
}

@media screen and (max-width: 991px) {
  .wrapper .sidebar {
    margin-left: 0 !important;
  }

  .wrapper.active .sidebar {
    margin-left: -225px !important;
  }
}


.Seconds {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Minutes {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 15px;
}

.Minutes .Number p {
  font-family: 'Open Sans', sans-serif;
  font-weight: 1000;
  font-size: 30px;
  font-style: normal;
}

.Seconds .Number p {
  font-family: 'Open Sans', sans-serif;
  font-weight: 1000;
  font-size: 30px;
  font-style: normal;
}

.Minutes .text p {
  font-family: 'Open Sans', sans-serif;
  font-size: 20px;
  font-weight: 1000;
  font-style: normal;
}

.Seconds .text p {
  font-family: 'Open Sans', sans-serif;
  font-size: 20px;
  font-weight: 1000;
  font-style: normal;
}

.CountDown {
  display: flex;
  flex-direction: column;
  margin-right: 17px;
  align-items: center;
  line-height: 25px;
}

.CountDown .CountDownHead p {
  font-family: 'Open Sans', sans-serif;
  font-weight: 1000;
  font-size: 30px;
  font-style: normal;
}

.Time {
  display: flex;
  flex-direction: row;
}

.SessionGame {
  display: flex;
  justify-content: space-between;
  margin-top: 70px;
  align-items: center;
}

.Period {
  margin-left: 17px;
  line-height: 20px;
}

.Period .id p {
  font-family: 'Open Sans', sans-serif;
  font-weight: 1000;
  font-size: 18px;
  font-style: normal;
}

.text p {
  font-family: 'Open Sans', sans-serif;
  font-weight: 1000;
  font-size: 30px;
  font-style: normal;
}

/* Number box css */

.Numberdiv {
  height: 50px;
  width: 50px;
  background-color: #434343;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
  margin-right: 8px;
  font-size: 16px;
}

.allNumber {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-right: -2%;
  margin-top: 10px;
  margin: 0px;
  margin-left: 15px;
  height: 224px;
  overflow-y: auto;
}


.mainNumberDiv {
  display: flex;
  justify-content: center;
}

@media screen and (min-width: 1286px) {
  .Numberdiv {
    margin-right: 7px !important;
  }
}

@media screen and (max-width: 1285px) {
  .Numberdiv {
    margin-right: 7px !important;
  }
}

@media screen and (max-width: 1024px) {
  .Numberdiv {
    margin-right: 9px !important;
  }
}

@media screen and (max-width: 540px) {
  .Numberdiv {
    height: 45px !important;
    width: 45px !important;
    margin-right: 7px !important;
  }
}

@media screen and (max-width: 431px) {
  .Numberdiv {
    height: 42px !important;
    width: 42px !important;
    margin-right: 2px !important;
  }
}

@media screen and (max-width: 415px) {
  .Numberdiv {
    height: 40px !important;
    width: 40px !important;
    margin-right: 3px !important;
  }
}

@media screen and (max-width: 399px) {
  .Numberdiv {
    margin-right: 4px !important;
  }
}

@media screen and (max-width: 393px) {
  .Numberdiv {
    height: 45px !important;
    width: 45px !important;
    margin-right: 6px !important;
  }
}

@media screen and (max-width: 365px) {
  .Numberdiv {
    height: 40px !important;
    width: 40px !important;
    margin-right: 7px !important;
  }
}

@media screen and (max-width: 343px) {
  .Numberdiv {
    height: 40px !important;
    width: 40px !important;
    margin-right: 5px !important;
  }
}

@media screen and (max-width: 328px) {
  .Numberdiv {
    height: 35px !important;
    width: 35px !important;
    margin-right: 7px !important;
  }
}

.Tablecontainer {
  max-width: 100%;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-top: 22px;
  height: 500px;
  overflow-y: auto;
}


table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  padding: 12px 15px;
  text-align: center;
  border-bottom: 1px solid #ddd;
}

th {
  background-color: #f2f2f2;
}

tr:hover {
  background-color: #f9f9f9;
}

/* .midPart {
  height: calc(100vh - 100px);
  overflow-y: auto !important;
} */

@media (max-height: 692px) {
  .wrapper .sidebar ul li a {
    padding: 9px 30px;
  }
}

@media (max-height: 632px) {
  .wrapper .sidebar ul li a {
    padding: 7px 30px;
    font-size: 12px;
  }

  .LogoutButton {
    text-align: center;
    width: 130px !important;
    margin-left: 20px;
    border-radius: 50px;
    margin-top: 20px;
  }
}

/* login page */

@import url('https://fonts.googleapis.com/css?family=Montserrat:400,800');

* {
  box-sizing: border-box;
}

.Login h1 {
  font-weight: bold;
  margin: 0;
}

.Login h2 {
  text-align: center;
}

.Login p {
  font-size: 14px;
  font-weight: 100;
  line-height: 20px;
  letter-spacing: 0.5px;
  margin: 20px 0 30px;
}

.Login span {
  font-size: 12px;
}

.Login a {
  color: #333;
  font-size: 14px;
  text-decoration: none;
  margin: 15px 0;
}

.Login button {
  border-radius: 20px;
  border: 1px solid #FF4B2B;
  background-color: #FF4B2B;
  color: #FFFFFF;
  font-size: 12px;
  font-weight: bold;
  padding: 12px 45px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: transform 80ms ease-in;
}

.Login button:active {
  transform: scale(0.95);
}

.Login button:focus {
  outline: none;
}

.Login button.ghost {
  background-color: transparent;
  border-color: #FFFFFF;
}

.Login form {
  background-color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 50px;
  height: 100%;
  text-align: center;
}

.Login input {
  background-color: #eee;
  border: none;
  padding: 12px 15px;
  margin: 8px 0;
  width: 100%;
}

.Login {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25),
    0 10px 10px rgba(0, 0, 0, 0.22);
  position: relative;
  overflow: hidden;
  width: 768px;
  max-width: 100%;
  min-height: 480px;
}

.form-container {
  position: absolute;
  top: 0;
  height: 100%;
  transition: all 0.6s ease-in-out;
}

.sign-in-container {
  left: 0;
  width: 50%;
  z-index: 2;
}

.container.right-panel-active .sign-in-container {
  transform: translateX(100%);
}

.sign-up-container {
  left: 0;
  width: 50%;
  opacity: 0;
  z-index: 1;
}

.container.right-panel-active .sign-up-container {
  transform: translateX(100%);
  opacity: 1;
  z-index: 5;
  animation: show 0.6s;
}

@keyframes show {

  0%,
  49.99% {
    opacity: 0;
    z-index: 1;
  }

  50%,
  100% {
    opacity: 1;
    z-index: 5;
  }
}

.overlay-container {
  position: absolute;
  top: 0;
  left: 50%;
  width: 50%;
  height: 100%;
  overflow: hidden;
  transition: transform 0.6s ease-in-out;
  z-index: 100;
}

.container.right-panel-active .overlay-container {
  transform: translateX(-100%);
}

.overlay {
  background: #FF416C;
  background: -webkit-linear-gradient(to right, #FF4B2B, #FF416C);
  background: linear-gradient(to right, #FF4B2B, #FF416C);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 0;
  color: #FFFFFF;
  position: relative;
  left: -100%;
  height: 100%;
  width: 200%;
  transform: translateX(0);
  transition: transform 0.6s ease-in-out;
}

.container.right-panel-active .overlay {
  transform: translateX(50%);
}

.overlay-panel {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 40px;
  text-align: center;
  top: 0;
  height: 100%;
  width: 50%;
  transform: translateX(0);
  transition: transform 0.6s ease-in-out;
}

.overlay-left {
  transform: translateX(-20%);
}

.container.right-panel-active .overlay-left {
  transform: translateX(0);
}

.overlay-right {
  right: 0;
  transform: translateX(0);
}

.container.right-panel-active .overlay-right {
  transform: translateX(20%);
}

.social-container {
  margin: 20px 0;
}

.social-container a {
  border: 1px solid #DDDDDD;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 0 5px;
  height: 40px;
  width: 40px;
}



@media (min-width: 1200px) {

  .h1,
  h1 {
    font-size: 2.4rem;
  }
}

@media (max-width: 655px) {

  .h1,
  h1 {
    font-size: 1.7rem;
  }

  .Login input {
    height: 40px;
    width: 90%;
  }
}

@media (max-width: 500px) {
  .social-container a {
    height: 30px;
    width: 30px;
  }

  .Login button {
    font-size: 12px !important;
    padding: 10px 30px !important;
  }
}

@media (max-width: 440px) {

  .h1,
  h1 {
    font-size: 1.2rem !important;
  }

  .Login input {
    height: 35px;
    width: 90%;
  }

  .social-container a {
    height: 25px;
    width: 25px;
  }

  .Login button {
    font-size: 10px !important;
    padding: 7px 20px !important;
  }

  .Login button {
    font-size: 8px !important;
    padding: 7px 10px !important;
  }

  .Login input::placeholder {
    font-size: 13px !important;
  }

  .Login input {
    padding-right: 0px;
    padding-left: 10px;
  }
}

@media (max-width: 410px) {
  .Login input {
    padding-right: 0px;
    padding-left: 10px;
  }

  .Login input::placeholder {
    font-size: 11px !important;
  }
}

@media (max-width: 655px) {

  .h1,
  h1 {
    font-size: 1.7rem;
  }

  .Login input {
    height: 40px;
    width: 90%;
  }
}

.LogoutButton {
  background-color: #434343 !important;
  width: 170px;
  margin-left: 30px;
  border-radius: 50px;
  margin-top: 20px;
}

.LogoutButton a:hover {
  background-color: grey !important;
  border-radius: 50px;
}

/* modal for betting  */

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  max-width: 400px;
  width: 100%;
  text-align: center;
}

.btn-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
  color: #ccc;
  font-size: 20px;
  padding-top: -10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-close:hover {
  color: #555;
}

h2 {
  margin-bottom: 15px;
}

.amount-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
}

.amount-input {
  width: 100px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-left: 10px;
  margin-right: 10px;
  /* Add space between text box and buttons */
}

.Numberbtn {
  padding: 8px 15px;
  border: none;
  cursor: pointer;
  background-color: #434343;
  color: #fff;
  border-radius: 5px;
}

.btn:hover {
  background-color: #727171;
}

.preset-amounts {
  display: flex;
  justify-content: center;
}

.btn-amount {
  margin: 5px;
}


/* Deposit page  */

.fullpage {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.w-100 {
  width: 100%;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

.w-3 {
  border: 1px solid #b8b8b8;
  margin-bottom: 40px;
  border: 1px solid #d4ddec;
  box-shadow: 0 0px 5px #d4ddec;
}

.w-3 {
  width: 440px;
}

.back-top {
  background: #eea8a8;
  box-shadow: 0 0 2px #ccc;
  padding: 5px 15px;
}

.back_btn {
  background: #727171;
  box-shadow: 0 0 2px #ccc;
  padding: 5px 15px;
  border-radius: 100%;
  height: 42px;
  width: 42px;
}

.back-top i {
  background: #721213;
  color: #fff;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 26px;
}

.text-ceter {
  text-align: center;
}

.addFunds {
  background: transparent;
  border: 1px solid #76aa7c;
  border-radius: 5px;
}

.addFunds {
  background: transparent;
  border: 1px solid #76aa7c;
  border-radius: 5px;
  color: #b97373;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 18px;
  padding: 6px 18px;
  margin-top: 25px;
  margin-bottom: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.addFunds i {
  color: #000;
  border: 1px solid #000;
  font-size: 16px;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
}

.d-flex {
  display: flex;
  align-items: center;
  justify-content: center;
}

.position-relative {
  position: relative;
}

.method-icon {
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.px-3 {
  padding: 15px;
}

.form-label {
  display: block;
  text-transform: capitalize;
  color: #b97373;
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 5px;
}

.method-icon {
  position: absolute;
  /* left: 15px; */
  top: 50%;
  transform: translateY(-50%);
}

.form-control {
  width: 100%;
  border: 1px solid #d4ddec;
  background: transparent;
  padding: 6px 10px;
  font-size: 16px;
  border-radius: 4px;
  color: #333;
  box-shadow: 0 0px 5px #d4ddec;

}

.form-control option {
  background: #fff;
}

.mb-3 {
  margin-bottom: 20px;
}

.ps-3 {
  padding-left: 45px;
}

.instructions-block {
  border: 1px solid #b8b8b85c;
  padding: 20px 12px;
  border-radius: 4px;
  border: 1px solid #d4ddec;
  box-shadow: 0 0px 5px #d4ddec;
}

.step-text {
  font-size: 14px;
  color: #671718;
  margin-bottom: 8px;
}

.qr-code {
  width: 150px;
}

.qr-code img {
  width: 100%;
  border: 2px solid #3f2682;
  padding: 8px;
}

.scanandpay-btn {
  width: 100%;
  background: #3f2682;
  color: #fff;
  border: 0;
  text-transform: uppercase;
  font-size: 18px;
  padding: 8px 5px;
  border-radius: 5px;
}

.upiid-text {
  color: #671718;
  font-size: 18px;
  font-weight: 600;
}

.upiid-text-text {
  color: #671718;
  font-size: 16px;
  margin-left: 15px;
}

.submit-btn {
  background: #671718;
  color: #fff;
  border: 0;
  font-size: 18px;
  padding: 8px 20px;
  border-radius: 4px;
  margin: 0px auto;
  display: inline-block;
  margin-top: 25px;
}

.support-btn {
  color: #671718;
  border: 1px solid #d4ddec;
  box-shadow: 0 0px 5px #d4ddec;
  background: transparent;
  font-weight: 600;
}

@media (max-width: 440px) {
  .w-3 {
    width: 100%;
    border: 0;
    box-shadow: none;
  }
}

.phonepeLogo {
  position: absolute;
  right: 10px !important;
  top: 50%;
  transform: translateY(-50%);
}

.sidenav {
  height: 100%;
  width: 200px;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #111;
  overflow-x: hidden;
  padding-top: 20px;
}

/* Style the sidenav links and the dropdown button */
.sidenav a,
.dropdown-btn {
  padding: 6px 8px 6px 16px;
  text-decoration: none;
  font-size: 20px;
  color: #818181;
  display: block;
  border: none;
  background: none;
  width: 100%;
  text-align: left;
  cursor: pointer;
  outline: none;
}

.sidenav a:hover,
.dropdown-btn:hover {
  color: #f1f1f1;
}

.main {
  margin-left: 200px;
  /* Same as the width of the sidenav */
  font-size: 20px;
  /* Increased text to enable scrolling */
  padding: 0px 10px;
}

.active {
  background-color: green;
  color: white;
}

.dropdown-container {
  display: none;
  background-color: #262626;
}

.depositContainer {
  position: absolute;
  width: 100% !important;
}

.btnyellow-custom:hover,
.btnyellow-custom:focus,
.btnyellow-custom:active,
.btnyellow-custom.active {
  background-color: #ffc107 !important;
  border-color: #ffc107 !important;
}

.btngreen-success-custom:hover,
.btngreen-success-custom:focus,
.btngreen-success-custom:active,
.btngreen-success-custom.active {
  background-color: #28a745 !important;
  /* Success button color */
  border-color: #28a745 !important;
  /* Border color */
}

.btnred-danger-custom:hover,
.btnred-danger-custom:focus,
.btnred-danger-custom:active,
.btnred-danger-custom.active {
  background-color: #dc3545 !important;
  /* Danger button color */
  border-color: #dc3545 !important;
  /* Border color */
}

.gradient-custom-2 {
  /* fallback for old browsers */
  background: #fccb90;

  /* Chrome 10-25, Safari 5.1-6 */
  background: -webkit-linear-gradient(to right, #ee7724, #d8363a, #dd3675, #b44593);

  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  background: linear-gradient(to right, #ee7724, #d8363a, #dd3675, #b44593);
}

@media (min-width: 768px) {
  .gradient-form {
    height: 100vh !important;
  }
}

@media (min-width: 769px) {
  .gradient-custom-2 {
    border-top-right-radius: .3rem;
    border-bottom-right-radius: .3rem;
  }
}

@media (max-width: 490px) {
  .CountDown .CountDownHead p {
    font-size: 22px;
  }

  .Minutes .Number p {
    font-size: 25px;
  }

  .Seconds .Number p {
    font-size: 25px;
  }

  .Minutes .text p {
    font-size: 15px;
  }

  .Seconds .text p {
    font-size: 15px;
  }
}

@media (max-width: 470px) {

  th,
  td {
    padding: 12px 5px;
    font-size: 13px;
  }

  .btn {
    padding: 0 5px !important;
    font-size: 12px !important;
  }
}

@media (max-width: 400px) {
  .gameName {
    font-size: 20px;
  }
}

::selection {
  background: #FF416C;
  color: #fff;
}

.wrapperP {
  overflow: hidden;
  max-width: 390px;
  background: #fff;
  padding: 30px;
  border-radius: 15px;
  box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.1);
}

.wrapperP .title-text {
  display: flex;
  width: 200%;
}

.wrapperP .title {
  width: 50%;
  font-size: 35px;
  font-weight: 600;
  text-align: center;
  transition: all 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.wrapperP .slide-controls {
  position: relative;
  display: flex;
  height: 50px;
  width: 100%;
  overflow: hidden;
  margin: 30px 0 10px 0;
  justify-content: space-between;
  border: 1px solid lightgrey;
  border-radius: 15px;
}

.slide-controls .slide {
  height: 100%;
  width: 100%;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  line-height: 48px;
  cursor: pointer;
  z-index: 1;
  transition: all 0.6s ease;
}

.slide-controls label.signup {
  color: #000;
}

.slide-controls .slider-tab {
  position: absolute;
  height: 100%;
  width: 50%;
  left: 0;
  z-index: 0;
  border-radius: 15px;
  background: -webkit-linear-gradient(left, #FF4B2B, #FF416C);
  transition: all 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

input[type="radio"] {
  display: none;
}

#signup:checked~.slider-tab {
  left: 50%;
}

#signup:checked~label.signup {
  color: #fff;
  cursor: default;
  user-select: none;
}

#signup:checked~label.login {
  color: #000;
}

#login:checked~label.signup {
  color: #000;
}

#login:checked~label.login {
  cursor: default;
  user-select: none;
}

.wrapperP .form-containerP {
  width: 100%;
  overflow: hidden;
}

.form-containerP .form-inner {
  display: flex;
  width: 200%;
}

.form-containerP .form-inner form {
  width: 50%;
  transition: all 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.form-inner form .field {
  height: 50px;
  width: 100%;
  margin-top: 20px;
}

.form-inner form .field input {
  height: 100%;
  width: 100%;
  outline: none;
  padding-left: 15px;
  border-radius: 15px;
  border: 1px solid lightgrey;
  border-bottom-width: 2px;
  font-size: 17px;
  transition: all 0.3s ease;
}

.form-inner form .field input:focus {
  border-color: #FF416C;
  /* box-shadow: inset 0 0 3px #fb6aae; */
}

.form-inner form .field input::placeholder {
  color: #999;
  transition: all 0.3s ease;
}

form .field input:focus::placeholder {
  color: #FF416C;
}

.form-inner form .pass-link {
  margin-top: 5px;
}

.form-inner form .signup-link {
  text-align: center;
  margin-top: 30px;
}

.form-inner form .pass-link a,
.form-inner form .signup-link a {
  color: #FF416C;
  text-decoration: none;
}

.form-inner form .pass-link a:hover,
.form-inner form .signup-link a:hover {
  text-decoration: underline;
}

form .btn1 {
  height: 50px;
  width: 100%;
  border-radius: 15px;
  position: relative;
  overflow: hidden;
}

form .btn1 .btn1-layer {
  height: 100%;
  width: 300%;
  position: absolute;
  left: -100%;
  background: -webkit-linear-gradient(left, #FF4B2B, #FF416C);
  border-radius: 15px;
  transition: all 0.4s ease;
  ;
}

form .btn1:hover .btn1-layer {
  left: 0;
  background: -webkit-linear-gradient(left, #FF4B2B, #FF416C);
}

form .btn1 input[type="submit"] {
  height: 100%;
  width: 100%;
  z-index: 1;
  position: relative;
  background: none;
  border: none;
  color: #fff;
  background: -webkit-linear-gradient(left, #FF4B2B, #FF416C);
  padding-left: 0;
  border-radius: 15px;
  font-size: 20px;
  font-weight: 500;
  cursor: pointer;
}

.content {
  background: #f5f6fa;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 111;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-container {
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  width: 300px;
  text-align: center;
}

.modal-heading {
  color: #000;
  font-size: 24px;
  margin-bottom: 10px;
}

.number-display {
  color: #000;
  font-size: 36px;
  margin-bottom: 20px;
}

.close-button {
  background-color: #000;
  color: #fff;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.close-button:hover {
  background-color: #333;
}


.modal1 {
  background: #142b56;
  border-radius: 20px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  width: 300px;
  text-align: center;
  padding: 20px;
  color: #fff;
  font-family: 'Arial', sans-serif;
  position: relative;
  min-height: 200px;
  overflow: hidden;
}

.modal1-content {
  position: relative;
  z-index: 1;
}

.victory-banner {
  background: #6a0dad;
  padding: 10px;
  border-radius: 10px;
  color: #ffeb3b;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

.score {
  margin-bottom: 15px;
}

.score-value {
  display: block;
  font-size: 76px;
  margin-top: 5px;
  font-weight: 600;
}

.close-button1 {
  background-color: #ffeb3b;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  color: #6a0dad;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s;
}

.close-button1:hover {
  background-color: #f1c40f;
}

.background-balls {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
}

.ball {
  position: absolute;
  border-radius: 50%;
  opacity: 0.6;
}

.ball.red {
  background-color: red;
  width: 30px;
  height: 30px;
  top: 10%;
  left: 10%;
}

.ball.blue {
  background-color: blue;
  width: 50px;
  height: 50px;
  top: 30%;
  right: 10%;
}

.ball.yellow {
  background-color: yellow;
  width: 40px;
  height: 40px;
  bottom: 20%;
  left: 50%;
  transform: translateX(-50%);
}

.ball.red2 {
  background-color: red;
  width: 20px;
  height: 20px;
  bottom: 10%;
  right: 20%;
  transform: translateX(-50%);
}

.ball.blue2 {
  background-color: blue;
  width: 35px;
  height: 35px;
  top: 20%;
  left: 20%;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.container {
  max-width: 800px;
  margin: 50px auto;
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  font-weight: bold;
}

.form-group input[type="text"],
.form-group textarea {
  width: 100%;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
  box-sizing: border-box;
  resize: vertical;
}

.form-group textarea {
  min-height: 150px;
}

.btn-submit {
  background-color: #333;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
}

.btn-submit:hover {
  background-color: #555;
}

.betHistorytableCss {
  overflow-y: visible !important;
  height: 100vh;
  margin-top: 60px;
  width: 100%;
  background-color: #f5f6fa;
}